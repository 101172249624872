<template>
    
    <h5 class="dashboard-content-subheader" v-if="compInfo != null">{{ $t("UI.projects") }} - {{ compInfo.title }}</h5>

    <div class="row">
        <div class="col-12 col-md-2 mb-3 align-items-stretch curs-p">
            <div class="card cobalt-card creation-card"
                @click="newProject()">
                <div class="card-body">
                    <h5 class="mb-0">{{ $t("UI.createProject") }}</h5>
                </div>
            </div>
        </div>        
        <div class="col-12 col-md-2 mb-3 align-items-stretch" v-for="(pr, index) in projects" :key="pr.id">
            <router-link :to="{ name: 'project', params: { id: pr.id } }"
                class="card cobalt-card">
                    <div class="card-body p-0 flex-column" :style="{ 'background-image': 'url(' + require('@/assets/images/dashboard/default_item_background.png') + ')' }">
                        <div class="cobalt-card__header">
                            <div class="dropdown">
                                <button class="btn btn-link btn-sm text-body-tertiary" :id="'taskDropdownMenuLink' + pr.id" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="@/assets/images/dashboard/more-vertical.svg">
                                </button>
                                <ul class="dropdown-menu dropdown-menu" :aria-labelledby="'taskDropdownMenuLink' + pr.id">
                                    <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary"><font-awesome-icon :icon="['fas', 'pencil']" /> {{ $t("UI.change") }}</button></li>
                                    <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click.stop.prevent="requestDelete($event, pr.id)"><font-awesome-icon :icon="['fas', 'trash']" /> {{ $t("UI.delete") }}</button></li>
                                </ul>
                            </div>
                        </div>
                        <div class="cobalt-card__body">
                        </div>
                        <div class="cobalt-card__footer">
                            <div class="cobalt-card__title">{{ pr.name }}</div>
                        </div>
                    </div>
            </router-link>
        </div>
    </div>

    <NewPrModal :modalID="modalProjectNewID" ref="NewPrModalRef">
        <template v-slot:header-left>{{ $t("UI.addProject") }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <form>
                    <div class="mb-3">
                        <label for="exampleInputLogin">{{ $t("UI.name") }}:</label>
                        <input type="text" class="form-control-log" id="exampleInputLogin" aria-describedby="loginHelp"
                            v-model="datComp.name">
                        <small id="loginHelp" class="form-text text-muted"></small>
                    </div>
                    <div class="mb-4">
                        <label for="exampleInputPassword">{{ $t("UI.description") }}:</label>
                        <textarea class="form-control-log" id="exampleInputPassword"
                            v-model="datComp.description"></textarea>
                    </div>
                    <div class="mb-4">
                        <!-- <label for="formFile" class="form-label">Default file input example</label> -->
                        <input class="form-control" type="file" id="formFile" @change="getFileInputValue">
                    </div>
                    <div v-if="datComp.errorText != ''">
                        <p class="mt-2 mb-0 text-danger">{{ datComp.errorText }}</p>
                    </div>
                </form>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-secondary btn-sm" @click="addProjectM()">{{ $t("UI.add") }}</button>
            </div>
        </template>
    </NewPrModal> 

    <DelPrModal :modalID="modalProjectDelID" ref="DelPrModalRef">
        <template v-slot:header-left>{{ $t("UI.addProject") }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <h2>{{ $t("UI.confirmation") }}</h2>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-danger btn-sm" @click="deleteProject">{{ $t("UI.delete") }}</button>
            </div>
        </template>
    </DelPrModal>   

</template>
<script>
import NewPrModal from "@/components/modals/BsModal.vue";
import DelPrModal from "@/components/modals/BsModal.vue";
import { mapGetters, mapActions } from 'vuex';

export default {
    props: ['compInfo', 'routePath', 'refreshMethod'],
    components: {
        NewPrModal,
        DelPrModal
    },
    data() {
        return {
            projects: [],
            datComp: {
                id: 0,
                company_id: 0,
                name: '',
                description: '',
                errorText: '',
            },
            dataParam: {
                pic_h: 400,
                pic_w: 600,
                company_id: 0
            },
            selectedID: 0,
            modalProjectNewID: 'newProjectModal', 
            modalProjectDelID: 'deleteProjectModal'            
        }
    },
    beforeMount: function () {
        // console.log(this.info);
        if (this.compInfo != null) {
            this.dataParam.company_id = this.compInfo.id
            this.datComp.company_id = this.compInfo.id
        }

        this.getInfo()
    },  
    methods: {
        ...mapActions('project', ['list', 'addProject', 'delete']),
        ...mapGetters('project', ['getList']),
        async getInfo(id) {
            await this.list(this.dataParam)
            this.projects = this.getList()
            // console.log(this.projects)
        },
        newProject() {
            this.$refs.NewPrModalRef.shows();
        },
        async addProjectM() {
            await this.addProject(this.datComp)
            this.getInfo()
            this.$refs.NewPrModalRef.hide()
        },
        requestDelete(event, id) {
            event.stopPropagation();
            event.preventDefault();

            this.selectedID = id
            this.$refs.DelPrModalRef.shows()
        },            
        async deleteProject() {
            await this.delete(this.selectedID)
            this.$refs.DelPrModalRef.hide()

            if (this.refreshMethod) {
                this.refreshMethod()
            } else {
                this.$router.go(0)
            }
        }        
    }
}
</script>