import * as companyApi from '@/api/companyApi.js';

export default {
    namespaced: true,
    state() {
        return {
            companyListDat: [],
            companyInfo: null,
        }
    },
    getters: {
        getList(state) {
            return state.companyListDat
        },
        getInfo(state) {
            return state.companyInfo
        }
    },
    mutations: {
        setList(state, listData) {
            state.companyListDat = JSON.parse(listData)
        },
        setInfo(state, listData) {
            state.companyInfo = JSON.parse(listData)
        },
        clearInfo(state) {
            state.companyInfo = null
        },
    },
    actions: {
        async list({ commit }) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(userData);

                const dataResp = await companyApi.listCompany(userData.token)

                // console.log(dataResp);

                if (dataResp) {
                    commit('setList', JSON.stringify(dataResp))
                }
            }
        },
        async info({ commit }, id) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await companyApi.getCompany(userData.token, id)

                // console.log(dataResp);

                if (dataResp) {
                    commit('setInfo', JSON.stringify(dataResp))
                }
            }
        },
        async delete({ commit }, id) {

            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await companyApi.delCompany(userData.token, id)

                // console.log(dataResp);

                if (dataResp) {
                    commit('clearInfo')
                }
            }
        },
        async addCompany({ commit }, data) {
            // console.log(data);
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await companyApi.updateCompany(userData.token, data)

                // console.log(dataResp);

                if (dataResp) {
                    commit('clearInfo')
                }
            }
        },
        async inviteCode({ commit }, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await companyApi.inviteCode(userData.token, data)

                if (dataResp) {
                    //commit('clearInfo')
                }

                return dataResp
            }
        },
        async sendInvitation({ commit }, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await companyApi.sendInvitation(userData.token, data)

                if (dataResp) {
                    //commit('clearInfo')
                }

                return dataResp
            }
        },        
    }
}