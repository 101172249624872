<template>
    <div class="modal fade" 
        :class="this.modalPosition" 
        :id="modalID" 
        :aria-labelledby="theModalLabel" 
        tabindex="-1"
        aria-hidden="true"
        @click.self="close">

        <div class="cobalt-modal__task-form modal-dialog modal-dialog-centered w-100" :class="modalClass">
            <div class="p-0 m-0 w-100 h-100">

                <div class="modal-content shadow">
                    <div class="modal-header border-0 pt-2 ps-4 pe-4 pb-2">
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <span class="cobalt-project-small me-1">MP</span> {{ projectName }}
                            <span class="cobalt-task__breadcrumb-separator ms-2 me-2"></span>
                            {{ statusName }}
                        </span>
                        <div class="d-flex justify-content-end flex-grow-1">
                            <button type="button" class="cobalt-toolbar-btn" data-bs-dismiss="modal" aria-label="Закрыть"><img src="@/assets/images/dashboard/base-close.svg" /></button>
                        </div>
                    </div>
                    <div class="modal-body ps-0 pe-0">
                        <div class="row p-0 m-0 w-100 h-100">
                            <div class="col p-0">
                                <div class="blocker" @click.self="closeSubtask()"></div>
                                <div class="ps-4 pe-4">
                                    <CobaltTaskForm 
                                        ref="taskForm"
                                        :taskData="this.taskData"
                                        :projectUsers="this.projectUsers"
                                        :colors="this.colors"
                                        @showModSubtask="showModSubtask"
                                        @removeSubtask="removeSubtask"
                                    />
                                </div>
                            </div>
                            <div class="col p-0" v-if="this.isSubtaskVisible">
                                <div class="ps-4 pe-4 h-100">
                                    <CobaltSubTaskForm 
                                        ref="subTaskForm"
                                        :taskData="this.subTaskData"
                                        :projectUsers="this.projectUsers"
                                        :parentId="this.taskData.id"
                                        :colors="this.colors"
                                        @refresh="refreshParent"
                                    />
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import CobaltTaskForm from "@/components/dashboard/parts/CobaltTaskForm.vue"
import CobaltSubTaskForm from "@/components/dashboard/parts/CobaltSubTaskForm.vue"
import { mapGetters, mapActions } from 'vuex'
import { Modal } from "bootstrap"
import { ref } from 'vue'

export default {
    components: {
        CobaltTaskForm,
        CobaltSubTaskForm,
    },
    props: {
        modalID: {
            type: String,
            required: true
        },
        modalSize: {
            type: String,
            default: ''  // или любой другой дефолт
        },
        modalPosition: {
            type: String,
            default: ''
        },
        statusName: {
            type: String,
            default: ''
        },
        projectName: {
            type: String,
            default: ''
        },
        taskData: {
            type: Object,
            required: true
        },
        projectUsers: {
            type: Array,
            required: true
        },
        colors: {
            type: Array,
            required: true
        }       
    },    
    data() {
        return {
            subTaskData: {
                parentName: '',
                statusName: '',
                id: 0,
                title: '',
                description: '',
                status_id: '',
                parent_id: 0,
                position: '',
                weight: 0,
                color: '#000000',
                errorText: '',
                date_create: 0,
                date_planned_end: '',
                date_planned_start: '',
                author: {},
                performer: [],
                files: [],
                priority: 0
            },  
            files: [],             
            theModal: null,
            theModalLabel: null,
            isSubtaskVisible: false
        }
    },
    computed: {
        modalClass: {
            get() {
                return this.isSubtaskVisible ? this.modalSize + " double" : this.modalSize
            }
        }
    }, 
    mounted: function () {
        var IdModal = '#' + this.$props.modalID
        this.theModalLabel = this.modalID + "Label"
        this.theModal = new Modal(IdModal, {backdrop: true})
    },
    methods: {
        ...mapActions('board', ['updatTsk', 'updatTskDate', 'clearTskDate', 'delTsk', 'archTsk', 'listPerformers', 'assignPerformer', 'detachPerformer', 'uploadFiles', 'deleteFile']),
        shows() {
            this.isSubtaskVisible = false;
            this.$refs.taskForm.initialize()

            this.theModal.show()
        },
        hide() {
            this.theModal.hide()
        },
        async close() {
            if (this.isSubtaskVisible) {
                this.closeSubtask()
            }

            let updateResult = await this.updateTask()

            if (updateResult) {
                this.taskData.id = updateResult.id
            }

            await this.$refs.taskForm.updateTaskDate()
            await this.$refs.taskForm.clearTaskDate()

            this.$emit('closeModal')
        },
        async closeSubtask() {
            console.log("***** CLOSE SUBTASK *****")
            let updateResult = await this.updateSubTask()

            if (updateResult) {
                this.subTaskData.id = updateResult.id
            }

            this.isSubtaskVisible = false;
        },
        refreshParent() {
            this.$emit('refresh')
        },
        createNewSubTask() {
            return {
                parentName: '',
                statusName: '',
                id: 0,
                title: '',
                description: '',
                status_id: '',
                parent_id: 0,
                position: '',
                weight: 0,
                color: '#000000',
                errorText: '',
                date_create: 0,
                date_planned_end: '',
                date_planned_start: '',
                author: {},
                performer: [],
                files: [],
                priority: 0
            }
        },         
        showModSubtask(id, parent_id) {
            //this.subTaskData.id = id
            
            if (id > 0) {
                this.modalTitle = this.$t("UI.task.change")
                this.modalBtnOk = this.$t("UI.change")

                this.taskData.subtask.forEach(elementT => {
                    if (id == elementT.id) {
                        this.subTaskData = {... elementT}
                    }
                });
            } else {
                this.modalTitle = this.$t("UI.addTask")
                this.modalBtnOk = this.$t("UI.add")

                this.subTaskData = this.createNewSubTask()
                this.subTaskData.parent_id = parent_id
            }

            this.isSubtaskVisible = true

            this.$nextTick(() => {
                if (this.$refs.subTaskForm) {
                    this.$refs.subTaskForm.initialize()
                }
            })
            
        }, 
        async updateTask() {
            let updateResult = await this.$refs.taskForm.updateTask()
            
            await this.refreshParent()

            return updateResult
        }, 
        async updateSubTask() {
            let updateResult = await this.$refs.subTaskForm.updateTask()
            let isNewSubtask = false;

            await this.refreshParent()

            // let subTaskIndex = -1;
            // this.taskData.subtask.forEach((obj, index) => {
            //     if (obj.id === updateResult.id) {
            //         subTaskIndex = index;
            //     }
            // });

            // if (subTaskIndex < 0) {
            //     this.taskData.subtask.push(updateResult)
            // } else {
            //     this.taskData.subtask[subTaskIndex] = updateResult
            // }

            return updateResult
        },                      
    }
}
</script>

<style>
    .modal {
        backdrop-filter: blur(5px);
    }
</style>