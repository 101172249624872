<template>
    <!-- :style="{ 'background-color': task.color != '#000000' ? task.color : null } -->
    <div class="cobalt-task mb-3">
        <div class="cobalt-task__card" @dblclick="showModTask(task.id, statusId)">
            <div class="cobalt-task__card-header d-flex align-items-center justify-content-between">
                <div class="cobalt-task__priority" 
                    :style="{ 
                        'color': task.priorityObj.color,
                        'background-color': task.priorityObj.bg
                    }
                ">
                    {{ task.priorityObj.label }}
                </div>
                <div class="d-flex align-items-center">
                    <div class="dropdown">
                        <button class="btn btn-link btn-sm text-body-tertiary" :id="'taskDropdownMenuLink' + task.id" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="@/assets/images/dashboard/more-vertical.svg">
                        </button>
                        <ul class="dropdown-menu dropdown-menu" :aria-labelledby="'taskDropdownMenuLink' + task.id">
                            <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="showModTask(task.id, statusId)"><font-awesome-icon :icon="['fas', 'pencil']" /> {{ $t("UI.task.change") }}</button></li>
                            <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="showArch(3, task.id)"><font-awesome-icon :icon="['fas', 'box']" /> {{ $t("UI.task.archive") }}</button></li>
                            <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="showDel(3, task.id)"><font-awesome-icon :icon="['fas', 'trash']" /> {{ $t("UI.task.delete") }}</button></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card-task__card-body">
                <h6 class="cobalt-task__title">{{ task.title }}</h6>
                <div class="cobalt-task__desc">{{ task.description }}</div>
                <div class="cobalt-task__tags" v-if="task.tags"><span class="tag tag-primary">New</span> <span class="tag tag-secondary">UI/UX design</span></div>
                <div class="cobalt-task__progress" v-if="task.progress > 0">progress</div>
                <div class="cobalt-task__performer" v-if="task.performer != ''">
                    <CobaltPerformersList :performers="task.performer" />
                </div>
            </div>
            <div class="card-task__card-progress">
                <div class="card-task__card-progressbar" :class="deadlineColor" :style="{ 'width': progress }"></div>
            </div>
            <div class="card-task__card-footer">
                <div class="card-task__card-footer-left">
                    <div v-if="task.date_planned_end > 0" class="cobalt-task__deadline me-2" :class="deadlineColor">{{ daysTo }} дн.</div>
                    <div class="cobalt-task__comments">01</div>
                </div>
                <div class="card-task__card-footer-right">
                    <div class="cobalt-task__done me-2" v-if="task.subtask.length > 0">
                        {{ task.subtask.length }}
                    </div>                    
                    <div class="cobalt-task__attachment" v-if="task.files.length > 0">
                        {{ (task.files.length).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}
                    </div>                    
                </div>
            </div>
        </div>
        <div class="cobalt-task__card-back"></div>
    </div>
</template>

<script>
import { localeDate, priorityOptions, lightenDarkenColor } from '@/utils/format'
import CobaltPerformersList from "@/components/dashboard/parts/CobaltPerformersList.vue"

export default {
    components: {
        CobaltPerformersList
    },
    name: 'TaskCard',
    props: {
        task: {
            type: Object,
            required: true
        },
        statusId: {
            type: Number,
            required: true
        }
    },
    computed: {
        deadlineColor: {
            get() {
                const start = this.task.date_planned_start
                const end   = this.task.date_planned_end

                if (start > 0 && end > 0) {
                    const section = ( end - start ) / 3
                    const current =  new Date().getTime() / 1000
                    const delta = current - start;
                    
                    if (current > end) {
                        return 'failed';
                    }
                    if (current < start + section) {
                        return 'level-0'
                    }
                    if (start + section < current && current < end - section) {
                        return 'level-1'
                    }
                    if (end - section < current && current < end) {
                        return 'level-2'
                    }                                        
                }
                return ''
            }
        },
        daysTo: {
            get() {
                const end   = this.task.date_planned_end
                const current =  new Date().getTime() / 1000

                if ( end > 0 && current < end) {   
                    return parseInt( ( end - current ) / 86400 )
                }
                return 0
            }
        },
        progress: {
            get() {
                const start = this.task.date_planned_start
                const end   = this.task.date_planned_end

                if (start > 0 && end > 0) {
                    const current =  new Date().getTime() / 1000

                    const progress = ( end - current ) * 100 / ( end - start )

                    return parseInt( progress ) + '%'
                }

                return '100%'
            }            
        }       
    },
    methods: {
        localeDate,
        lightenDarkenColor,
        showModTask(taskId, statusId) {
            this.$emit('showModTask', taskId, statusId);
        },
        showArch(type, id) {
            this.$emit('showArch', type, id);
        },        
        showDel(type, id) {
            this.$emit('showDel', type, id);
        }
    }
}
</script>