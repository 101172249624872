<template>

    <CobaltDashboard :info="null" :company="compInfo?.title" :withFilterBar="false" contentClasses="mt-3 ps-0">
        <div>
            <div class="dashboard-projects__wrapper">
                <Projects v-if="compInfo" :compInfo="compInfo" />
            </div>
        </div>
    </CobaltDashboard>

    <UserModal :modalID='modalUser.id' modalSize="modal-md" ref="UserModal">
      <template v-slot:header-left>
          <div class=" d-dlex">
              <h2 class="mb-0">{{ modalUser.title }}</h2>
              <font-awesome-icon :icon="['fas', 'arrow-right-long']" />
              <button type="button" class="btn btn-link btn-sm" @click="shTables()">{{ modalUser.btnTitle }}</button>
          </div>
      </template>
      <template v-slot:body>
          <div class="modal-body">
              <div v-if="compInfo != null">
                  <UserDash v-if="modalUser.isUser" :info='compInfo'></UserDash>
                  <RoleDash v-else :info='compInfo'></RoleDash>
              </div>
          </div>
          <div class="modal-footer p-1">
              <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                  data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
          </div>
      </template>
    </UserModal>
</template>
<script>
import Navbar from "@/views/board/Navbar.vue"
import UserDash from "@/views/user/UserDash.vue"
import RoleDash from "@/views/user/RoleDash.vue"
import UserModal from "@/components/modals/BsModal.vue"
import Projects from "@/views/project/Projects.vue"
import CobaltDashboard from "@/components/dashboard/CobaltDashboard.vue";
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        Navbar,
        UserModal,
        UserDash,
        RoleDash,
        Projects,
        CobaltDashboard,      
    },
    data() {
        return {
            compInfo: null,
            menuTop: {
                user: true,
                task: false
            },
            modalUser: {
                id: 'modalUsersLis',
                size: 'modal-xl',
                title: this.$t("UI.users"),
                btnTitle: this.$t("UI.roles"),
                isUser: true
            },
        }
    },
    beforeMount: function () {
        this.getInfoComp()
    },
    methods: {
        ...mapActions('company', ['info']),
        ...mapGetters('company', ['getInfo']),
        async getInfoComp() {
            await this.info(this.$route.params.id)
            this.compInfo = this.getInfo()
            // console.log(this.compInfo)
        },
        async showUser() {
            // console.log(this.listUsers)
            this.$refs.UserModal.shows()
        },
        shTables() {
            if (this.modalUser.isUser) {
                this.modalUser.isUser = false
                this.modalUser.title = this.$t("UI.roles")
                this.modalUser.btnTitle = this.$t("UI.users")
            } else {
                this.modalUser.isUser = true
                this.modalUser.title = this.$t("UI.users")
                this.modalUser.btnTitle = this.$t("UI.roles")
            }
        },
        showDel(type, id) {
            this.$refs.DelModal.shows();
        }
    }
}
</script>

<style>
.over-bg {
    background-color: rgba(0, 58, 92, 0.616);
}
</style>