import { createRouter, createWebHistory } from "vue-router"

import store from "@/store"
import AppHome from "../views/Home";
import AppLogin from "../views/Login";
import AppRegister from "../views/Register";
import AppE404 from '../views/E404';
import AppCompany from '../views/Company';
import AppProject from '../views/ProjectOne';
import AppInvite from '../views/Invite';

const routes = [
    {
        name: 'home',
        path: '/',
        component: AppHome,
        meta: { auth: true }
    },
    {
        name: 'company',
        path: '/company/:id',
        component: AppCompany,
        meta: { auth: true }
    },
    {
        name: 'project',
        path: '/project/:id',
        component: AppProject,
        meta: { auth: true }
    },
    {
        name: 'login',
        path: '/login',
        component: AppLogin
    },
    {
        name: 'register',
        path: '/register',
        component: AppRegister
    },
    {
        name: 'invite',
        path: '/invite/:code',
        component: AppInvite
    },
    {
        path: '/:any(.*)', // .*
        component: AppE404
    }
];

const router = createRouter({
    routes,
    history: createWebHistory()
});

router.beforeEach((to, from, next) => {
    let isLogin = localStorage.getItem('user')
    
    if (to.meta.auth && !isLogin) {
        // console.log(isLogin)
        next({ name: 'login' })
    } else {
        if (to.name == 'login' && isLogin) {
            next({ name: 'home' });
        } else {
            next();
        }
    }

    next();

});

export default router;