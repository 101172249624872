<template>

    <CobaltDashboard :info="null" :company="prInfo.title" :project="pojctInfo" :withFilterBar="true" contentClasses="p-0" @switchMode="switchDashboardMode" @showMod="showMod">
        <div v-if="this.dashboardMode == 'canban'">
            <VueDraggable v-model="listStat" class="cobalt-statuses__wrapper" animation="200" @end="onEnd">
            <div class="cobalt-status-column__wrapper" v-for="elemStat in listStat" :key="elemStat.id">
                <div class="cobalt-status-column">
                    <div class="cobalt-status-column__header d-flex align-items-center"
                        :style="{ 'border-color': elemStat.color != '#000000' ? elemStat.color : null }"
                    >
                        <span class="cobalt-bullet" :style="{ 'background-color': elemStat.color != '#000000' ? elemStat.color : null }"></span>
                        <h6 class="title">{{ elemStat.title }} <span class="tasks-count" v-if="tasks[elemStat.id] && tasks[elemStat.id].length > 0">{{`0${tasks[elemStat.id].length}`.slice(-2)}}</span></h6>
                        <div class="d-flex align-items-left">
                            <button type="button" class="btn btn-link btn-sm text-body-tertiary pe-0"
                                @click="showModTask(0, elemStat.id)"><img src="@/assets/images/dashboard/task-add.svg"></button>                                        

                            <div class="dropdown">
                                <button class="btn btn-link btn-sm text-body-tertiary pe-0" :id="'taskDropdownMenuLink' + elemStat.id" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="@/assets/images/dashboard/more-vertical.svg">
                                </button>
                                <ul class="dropdown-menu dropdown-menu" :aria-labelledby="'taskDropdownMenuLink' + elemStat.id">
                                    <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary"
                                        @click="showModStatRole(elemStat.id)"><font-awesome-icon
                                            :icon="['fas', 'users-gear']" /> {{ $t("UI.statusRoles") }}</button></li>
                                    <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary"
                                        @click="showMod(elemStat.id)"><font-awesome-icon
                                            :icon="['fas', 'pencil']" /> {{ $t("UI.changeStatus") }}</button></li>
                                    <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm text-body-tertiary"
                                        @click="showDel(2, elemStat.id)"><font-awesome-icon
                                            :icon="['fas', 'trash']" /> {{ $t("UI.deleteStatus") }}</button></li>
                                </ul>
                            </div>                                            
                        </div>
                    </div>
                    <div class="cobalt-status-column__body pt-2 pb-2">
                        <h6 class="mb-0 p-3 text-center" v-if="tasks[elemStat.id].length == 0">{{ $t("message.noRecordsAvailable") }}</h6>
                        <VueDraggable class="drop-zone" v-model="tasks[elemStat.id]" animation="200" group="tasks" @end="onEnd">
                            <TaskCard
                                v-for="elemTask in tasks[elemStat.id]"
                                :key="elemTask.id"
                                :task="getPreparedTask(elemTask)"
                                :statusId="elemStat.id"
                                @showModTask="showModTask"
                                @showArch="showArch"
                                @showDel="showDel"
                            />
                        </VueDraggable>
                    </div>
                </div>
            </div>         
            </VueDraggable>
            <!--
            <div class="overflow-hidden ps-4">
                <button class="btn btn-lg cobalt-status-column__button" @click="showMod(0)">{{ $t("UI.addStatus") }}</button>
            </div>          
            -->
        </div>
        
        <div v-if="this.dashboardMode == 'calendar'" class="is-light-mode">
            <Qalendar 
                :config="this.calendarConfig" 
                :events="calendarEvents"
            />
        </div>
    </CobaltDashboard>

    <BsModal  :modalID='modalID' modalPosition="right" ref="BsModal" class="cobalt-base-dialog">
        <template v-slot:header-left>{{ modalTitle }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <form @submit.prevent="updateStatus(0)">
                    <div class="mb-3">
                        <label for="statusTitle">{{ $t("UI.name") }}:</label>
                        <input type="text" class="form-control" id="statusTitle" aria-describedby="loginHelp"
                            v-model="datUpdateStatus.title">
                        <small id="loginHelp" class="form-text text-muted"></small>
                    </div>
                    <div class="mb-4">
                        <label for="statusDescription">{{ $t("UI.description") }}:</label>
                        <textarea class="form-control" id="statusDescription"
                            v-model="datUpdateStatus.description"></textarea>
                    </div>
                    <hr>
                    <div class="mb-1">
                        <label>{{ $t("UI.backgroundColor") }}</label>
                    </div>
                    <div class="mb-4">
                        <div class="form-check form-check-inline" v-for="itemColor in colors">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                :checked="itemColor == datUpdateStatus.color" @change="chColor(itemColor)"
                                id="inlineRadio1" :value="itemColor"
                                :style="{ 'border-color': itemColor, 'border-width': '3px' }">
                            <label class="form-check-label" for="inlineRadio1"></label>
                        </div>
                    </div>
                    <div v-if="datUpdateStatus.errorText != ''">
                        <p class="mt-2 mb-0 text-danger">{{ datUpdateStatus.errorText }}</p>
                    </div>
                </form>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-secondary btn-sm" @click="updateStatus(0)">{{ modalBtnOk
                    }}</button>
            </div>
        </template>
    </BsModal>

    <TskModal 
        :modalID='modalIDTsk' 
        :statusName="datUpdateTask.statusName"
        :projectName="prInfo.title"
        :taskData="this.datUpdateTask"
        :projectUsers="this.projectUsers"
        :colors="this.colors"
        modalPosition="right" 
        modalSize="modal-lg cobalt-modal-lg"
        ref="TskModal" 
        @closeModal="closeTaskModal"
        @refresh="updTstts">
    </TskModal>

    <DelModal :modalID='modalIDel'  modalPosition="center" ref="DelModal" class="cobalt-base-dialog">
        <template v-slot:header-left>{{ deleteType.title }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <h2>{{ $t("UI.confirmation") }}</h2>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-danger btn-sm" @click="deleteAll">{{ $t("UI.delete") }}</button>
            </div>
        </template>
    </DelModal>
    <ArchModal :modalID='modalIArch'  modalPosition="center" ref="ArchModal" class="cobalt-base-dialog">
        <template v-slot:header-left>{{ archiveType.title }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <h2>{{ $t("UI.confirmation") }}</h2>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-danger btn-sm" @click="archiveAll">{{ $t("UI.task.archive") }}</button>
            </div>
        </template>
    </ArchModal>    
    <RoleModal :modalID='modalRole.id' modalSize="modal-md" modalPosition="center" ref="RoleModal" class="cobalt-base-dialog">
        <template v-slot:header-left>
            <div class="d-dlex">
                <h2 class="mb-0">{{ modalRole.title }}</h2>
            </div>
        </template>
        <template v-slot:body>
            <div class="modal-body">
                <div v-if="prInfo != null">
                    <RoleProject v-if="modalRole.isProject" :info='prInfo'></RoleProject>
                    <RoleStatus v-else :info='prInfo'></RoleStatus>
                </div>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
            </div>
        </template>
    </RoleModal>  
</template>

<script>
import Navbar from "@/views/board/Navbar.vue"
import BsModal   from "@/components/modals/BsModal.vue"
import DelModal  from "@/components/modals/BsModal.vue"
import ArchModal from "@/components/modals/BsModal.vue"
import RoleModal from "@/components/modals/BsModal.vue"
import TskModal  from "@/components/modals/TaskModal.vue"
import TaskCard  from "@/components/TaskCard.vue" 
import CobaltDashboard from "@/components/dashboard/CobaltDashboard.vue"
import CobaltProfile   from "@/components/dashboard/parts/CobaltProfile.vue"
import CobaltPerformer from "@/components/dashboard/parts/CobaltPerformer.vue"
import CobaltPerformersList from "@/components/dashboard/parts/CobaltPerformersList.vue"
import RoleProject from "@/views/user/RoleProject.vue"
import RoleStatus  from "@/views/user/RoleStatus.vue"
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Multiselect from 'vue-multiselect'
import { ref } from 'vue'
import { VueDraggable } from 'vue-draggable-plus'
import { mapGetters, mapActions } from 'vuex'
import { localeDate, priorityOptions, eventDate } from '@/utils/format'
import { Qalendar } from "qalendar";
import "qalendar/dist/style.css";

export default {
    components: {
        Navbar,
        BsModal,
        DelModal,
        ArchModal,
        TskModal,
        VueDraggable,
        DatePicker,
        Multiselect,
        RoleModal,
        RoleProject,
        RoleStatus,
        TaskCard,
        CobaltDashboard,
        CobaltProfile,
        CobaltPerformer,
        CobaltPerformersList,
        Qalendar
    },
    data() {
        return {
            dashboardMode: 'canban',
            pojctInfo: null,
            prInfo: {
                id: 0,
                title: ''
            },
            calendarConfig: {
                defaultMode: 'month',
            },
            statusInfo: null,
            listStat: [],
            modalID: 'modalNewStatus',
            modalIDel: 'ModDelete',
            modalIArch: 'ModArchive',
            modalIDTsk: 'modalIDTsk',
            menuTop: {
                user: false,
                task: true,
                roles: true
            },
            projectUsers: [],
            datUpdateStatus: {
                id: 0,
                title: '',
                description: '',
                project_id: '',
                weight: 0,
                color: '#000000',
                errorText: ''
            },
            datUpdateTask: {
                statusName: '',
                id: 0,
                title: '',
                description: '',
                status_id: '',
                position: '',
                weight: 0,
                color: '#000000',
                errorText: '',
                date_create: 0,
                date_planned_end: '',
                date_planned_start: '',
                author: {},
                performer: [],
                files: [],
                priority: 0
            },    
            modalRole: {
                id: 'modalRoleLis',
                size: 'modal-xl',
                title: this.$t("UI.projectRoles"),
                isProject: true
            },        
            lSat: [],
            tasks: [],
            files: [],
            modalTitle: '',
            modalBtnOk: '',
            colors: [ '#E1BEE7', '#C5CAE9', '#BBDEFB', '#C8E6C9', '#FFF9C4', '#FFE0B2', '#FFCDD2', '#000000' ],
            archiveType: {
                type: 0,
                id: 0,
                title: ''
            },
            deleteType: {
                type: 0,
                id: 0,
                title: ''
            }
        }
    },
    beforeMount: function () {
        this.getInfoComp()
        this.loadUsers()
    }, 
    computed: {
        priorityValues() {
            return [
                { value: 0, bg: "#ebebeb", color: "#262626", label: this.$t('UI.task.priority_0') },
                { value: 1, bg: "#FCEBE6", color: "#9B2406", label: this.$t('UI.task.priority_1') },
                { value: 2, bg: "#fafadb", color: "#816403", label: this.$t('UI.task.priority_2') },
                { value: 3, bg: "#dbfae3", color: "#03590b", label: this.$t('UI.task.priority_3') },
                { value: 4, bg: "#d4edfc", color: "#0e4f9a", label: this.$t('UI.task.priority_4') }
            ];
        },               
        selectedPriority: {
            get() {
                return this.priorityValues.find(option => option.value === this.datUpdateTask.priority) || null
            },
            set(option) {
                this.datUpdateTask.priority = option ? option.value : null
            }
        },
        calendarEvents: {
            get() {
                let events = [];


                for (const [statusId, statusItem] of Object.entries(this.tasks)) {
                 
                    statusItem.forEach(taskItem => {
                        let event = {
                            title: taskItem.title,
                            with: '',
                            time: {},
                            colorScheme: "sports",
                            isEditable: false,
                            id: taskItem.id,
                            description: taskItem.description
                        }

                        if (taskItem.date_planned_start && taskItem.date_planned_end) {
                            let timeStart = {
                                start: eventDate(taskItem.date_planned_start),
                                end: eventDate(taskItem.date_planned_end),
                            }
                            event.time = timeStart;
                            events.push(event)
                        }
                    })
                }

                return events
            }
        }
    },    
    methods: {
        ...mapActions('project', ['info', 'delete', 'listUsers']),
        ...mapGetters('project', ['getInfo', 'getListUsers']),
        ...mapActions('board', ['list', 'updatSt', 'delSt', 'updatTsk', 'updatTskDate', 'clearTskDate', 'delTsk', 'archTsk', 'listPerformers', 'assignPerformer', 'detachPerformer', 'uploadFiles', 'deleteFile', 'sortAll']),
        ...mapGetters('board', ['getList']),        
        localeDate, 
        switchDashboardMode(mode) {
            this.dashboardMode = mode
        },
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date < today;
        },        
        async closeTaskModal() {
            console.log('***** TASK MODAL CLOSED!!! *****')
        },  
        createNewTask() {
            return {
                statusName: '',
                id: 0,
                title: '',
                description: '',
                status_id: '',
                position: '',
                weight: 0,
                color: '#000000',
                errorText: '',
                date_create: 0,
                date_planned_end: '',
                date_planned_start: '',
                author: {},
                performer: [],
                files: [],
                priority: 0
            }
        },
        removePerformer(option, id) {
            const updatedPerformers = this.datUpdateTask.performer.filter(item => item.id !== option.id)
            this.datUpdateTask.performer = updatedPerformers
            this.updateTaskPerformer(false, false, option)
        },
        getPreparedTask(task) {
            const priorityObj = this.priorityValues.find(option => option.value === task.priority);
            return {
                ...task,
                priorityObj
            };
        },        
        async getInfoComp() {
            await this.info(this.$route.params.id)
            this.pojctInfo = this.getInfo()

            this.prInfo.title = this.pojctInfo.name
            this.prInfo.id = this.$route.params.id

            this.updTstts()
            this.datUpdateStatus.project_id = this.$route.params.id
            console.log(this.$route.params.id)
        },
        async loadUsers() {
           await this.listUsers(this.$route.params.id)
           this.projectUsers = this.getListUsers()
        },
        showMod(id) {
            //this.datUpdateStatus.id = id
            if (id > 0) {
                this.modalTitle = this.$t("UI.changeStatus")
                this.modalBtnOk = this.$t("UI.change")
                this.listStat.forEach(element => {
                    if (id == element.id) {
                        this.datUpdateStatus = {... element}
                    }
                });
            } else {
                this.modalTitle = this.$t("UI.addStatus")
                this.modalBtnOk = this.$t("UI.add")
                this.datUpdateStatus = {... {
                    id: 0, title: '', description: '', project_id: this.$route.params.id, weight: 0, color: '#000000'
                } }
            }

            this.$refs.BsModal.shows();
        },
        showModTask(id, status) {
            //this.datUpdateTask.id = id
            
            if (id > 0) {
                this.modalTitle = this.$t("UI.task.change")
                this.modalBtnOk = this.$t("UI.change")
                this.tasks[status].forEach(elementT => {
                    if (id == elementT.id) {
                        this.datUpdateTask = {... elementT}
                    }
                });
            } else {
                this.modalTitle = this.$t("UI.addTask")
                this.modalBtnOk = this.$t("UI.add")

                this.datUpdateTask = createNewTask()
            }
            
            this.listStat.forEach(elementS => {
                if (status == elementS.id) {
                    this.datUpdateTask.statusName = elementS.title
                    this.datUpdateTask.status_id  = elementS.id
                }
            }); 

            this.$refs.TskModal.shows();
        },       
        showDel(type, id) {
            this.deleteType.type = type
            this.deleteType.id = id

            if (type == 1) {
                this.deleteType.title = this.$t("UI.deleteCompany")
            } else if (type == 2) {
                this.deleteType.title = this.$t("UI.deleteStatus")
            } else if (type == 3) {
                this.deleteType.title = this.$t("UI.task.delete")
            }

            this.$refs.DelModal.shows();
        },
        showArch(type, id) {
            this.archiveType.type = type
            this.archiveType.id = id

            if (type == 1) {
                this.archiveType.title = this.$t("UI.archiveCompany")
            } else if (type == 2) {
                this.archiveType.title = this.$t("UI.archiveStatus")
            } else if (type == 3) {
                this.archiveType.title = this.$t("UI.task.archive")
            }

            this.$refs.ArchModal.shows();
        },        
        async updTstts() {
            await this.list(this.pojctInfo.id)
            let allStatus = this.getList()
            this.tasks = {};
            this.listStat = [];

            if (allStatus.length > 0) {
                let indx = 0
                allStatus.forEach(element => {
                    let ts = [];
                    let indxT = 0;
                    element.tasks.forEach(elementTs => {
                        ts[indxT] = elementTs
                        indxT++

                        if (this.datUpdateTask.id == elementTs.id) {
                            this.datUpdateTask = elementTs
                        }                        
                    });

                    this.tasks[element.id] = ts

                    this.listStat[indx] = element
                    indx++
                });
            }
        },
        chColor(val) {
            this.datUpdateStatus.color = val
        },
        chColorTsk(val) {
            this.datUpdateTask.color = val
        },               
        updateParagraph(text) {
            console.log(text)
        },
        async deleteProject() {
            await this.delete(this.pojctInfo.id)
            this.$refs.DelModal.hide()
            this.$router.push({ name: 'home' })

        },
        async deleteStatus() {
            await this.delSt(this.deleteType.id)

            this.updTstts()
            this.$refs.DelModal.hide()
        },
        async deleteTask() {
            await this.delTsk(this.deleteType.id)

            this.updTstts()
            this.$refs.DelModal.hide()
        },
        async archiveTask() {
            await this.archTsk(this.archiveType.id)

            this.updTstts()
            this.$refs.ArchModal.hide()            
        },
        async updateStatus() {
            await this.updatSt(this.datUpdateStatus)

            this.updTstts()
            this.$refs.BsModal.hide()
        },     
        updateCurrentTaskData() {
            //
        },    
        archiveAll() {
            if (this.archiveType.type == 1) {
                this.archiveProject()
            } else if (this.archiveType.type == 2) {
                this.archiveStatus()
            } else if (this.archiveType.type == 3) {
                this.archiveTask()
            }
        },
        deleteAll() {
            if (this.deleteType.type == 1) {
                this.deleteProject()
            } else if (this.deleteType.type == 2) {
                this.deleteStatus()
            } else if (this.deleteType.type == 3) {
                this.deleteTask()
            }
        },        
        onEnd(e) {
            // console.log(this.tasks)
            let arr = [];
            let indx = 0;
            this.listStat.forEach(element => {
                let arrTsk = []
                let indxTsk = 0
                this.tasks[element.id].forEach(elementTsk => {
                    arrTsk[indxTsk] = elementTsk.id
                    indxTsk++
                });
                arr[indx] = { 'id': element.id, 'task': arrTsk }

                indx++
            });

            let jsonStat = JSON.stringify(arr)
            this.sortAll(jsonStat)

            // console.log(jsonStat);
        },
        showRole() {
            this.modalRole.isProject = true
            this.modalRole.title = this.$t("UI.projectRoles")
            this.$refs.RoleModal.shows()
        },
        showModStatRole() {
            this.modalRole.isProject = false
            this.modalRole.title = this.$t("UI.statusRoles")
            this.$refs.RoleModal.shows()
        },    
    }

}
</script>

<style>
    .over-bg {
        background-color: rgba(0, 58, 92, 0.616);
    }

    .btn svg {
        fill: #747373;
    }
</style>