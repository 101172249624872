<template>
    <div class="d-lg-flex half">
      <div 
        class="bg order-1 order-md-2 auth-bg d-none d-lg-block" 
        :style="{'background-image': 'url(' + require('@/assets/images/auth/' + $i18n.locale +  '/welcome.svg') + ')'}"
      >
      </div>
      <div class="contents order-2 order-md-1">
  
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-7 text-center">              

                        <div class="cobalt-auth mb-4 text-lg-left">
                            <img :src="require('@/assets/images/auth/' + $i18n.locale +  '/invite.svg')" :alt="$t('auth.registration')" />
                        </div>

                        <div class="cobalt-auth mb-4 text-lg-left">
                            <p class="mt-4 mb-4">
                                Добро пожаловать в Cobalt!
                                Вас пригласили в проект<br><b>"ПРОЕКТ ОДИН"</b>.
                            </p>

                            <button type="button" class="btn cobalt-btn-primary btn-lg flex-fill mb-4" @click="tryLogin">Присоединиться</button>

                            <p>
                                Вам нужно <a href="../register">зарегистрироваться</a>, если Вы еще не зарегистрированы в системе Сobalt<br />
                                Или <a href="../login">войти</a>, если у Вас уже есть учетная запись.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {},
    data() {
        return {
            authData: {
                login: '',
                password: '',
                errorText: ''
            }
        }
    },
    computed: {

    },
    beforeMount: function () {
        this.getLinkBack()
    },     
    methods: {
        ...mapActions('user', ['login']),
        async getLinkBack() {
            let code = this.$route.params.code

            if (login) {
                this.$router.push({ name: 'home' })
            }
            else {

                this.authData.errorText = $t("error.wrongPassword");
            }
        }
    }
}
</script>

<style>
.btn-log {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #880652 !important;
    --bs-btn-border-color: #065588 !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #054068 !important;
    --bs-btn-hover-border-color: #054068 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #054068;
    --bs-btn-active-border-color: #054068;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #065588;
    --bs-btn-disabled-border-color: #065588;
}

.form-control-log {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #C8D8E8;
    background-clip: padding-box;
    border: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control-log:focus {
    color: #000000;
    background-color: #a4b2c0;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0);
}
</style>